import { Injectable } from '@angular/core';
import { ethers } from 'ethers';
import { BehaviorSubject, Observable } from 'rxjs';

import { PROVIDER_CONFIGS } from '../config';
import { ChainId } from '../types';

@Injectable({
    providedIn: 'root',
})
export class ProviderFactory
{
    public providersState$: BehaviorSubject<{ [ key in ChainId ]?: any }> = new BehaviorSubject<{ [ key in ChainId ]?: any }>({});

    private providers: { [ key in ChainId ]?: any } = {};
    private readonly providerConfigs: Partial<Record<ChainId, string[]>> = PROVIDER_CONFIGS;

    public getProvider(_chainId: ChainId): ethers.providers.BaseProvider
    {
        if (this.providers[ _chainId ]) return this.providers[ _chainId ];

        const provider = this.getEvmProvider(_chainId);

        return provider;
    }

    public setProvider(_chainId: ChainId, provider_: any)
    {
        this.providers[ _chainId ] = provider_;

        this.providersState$.next(this.providers);
    }

    public resetProvider(_chainId: ChainId)
    {
        this.getProvider(_chainId);
    }

    get providersState(): Observable<{ [ key in ChainId ]?: any }>
    {
        return this.providersState$.asObservable();
    }

    private getEvmProvider(_chainId: ChainId): ethers.providers.BaseProvider
    {
        const urls = <string[]>this.providerConfigs[ _chainId ];

        let rpcProviders = urls.map(url =>
        {
            return new ethers.providers.StaticJsonRpcProvider(url, _chainId);
        });

        const evmProvider = new ethers.providers.FallbackProvider(rpcProviders, 1);

        return evmProvider;
    }
}
